/* eslint-disable consistent-return */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faNewspaper, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card } from "react-bootstrap";
import CustomLink from "../../components/common/CustomLink/CustomLink";
import { helperContainer } from "./Search.module.scss";
import { ISearchItem } from "./SearchProps";
import { getPagePath } from "../../services/utilities";
import VendorIcon from "../../svg/VendorIcon.svg";
const ItemContent = ({
  icon,
  titleString,
  summary,
}: {
  icon: IconProp;
  titleString: string;
  summary: string;
}) => (
  <>
    <div className="title">
      <FontAwesomeIcon icon={icon} color="#5fafd0" />
      <p className="title-text"> {titleString}</p>
    </div>
    <div className="summary" dangerouslySetInnerHTML={{ __html: summary }} />
  </>
);

const NewsItem = ({
  titleString,
  summary,
  title,
}: {
  titleString: string;
  summary: string;
  title: string;
}) => (
  <CustomLink className="search-link" to={`/news/${getPagePath(title)}`}>
    <ItemContent
      icon={faNewspaper}
      titleString={titleString}
      summary={summary}
    />
  </CustomLink>
);
const ResourceItem = ({
  titleString,
  listItemId,
  summary,
  webUrl,
}: {
  titleString: string;
  listItemId: string;
  summary: string;
  webUrl: string;
}) => (
  <a className="search-link" href={webUrl} target="_blank" id={listItemId}>
    <ItemContent
      icon={faFileLines}
      titleString={titleString}
      summary={summary}
    />
  </a>
);
const VendorsItem = ({
  titleString,
  summary,
}: {
  titleString: string;
  summary: string;
}) => (
  <>
    <div className="vendor-title">
      <div className="vendorIcon">
        <VendorIcon />
      </div>

      <p className="title-text"> {titleString}</p>
    </div>
    <div className="summary" dangerouslySetInnerHTML={{ __html: summary }} />
  </>
);
export const SearchHelpers = (props: { data: ISearchItem }) => {
  const {
    data: { title, searchCategory, summary, listItemId, webUrl },
  } = props;
  const titleString = title.length > 100 ? `${title.slice(0, 100)}...` : title;
  const renderCard = () => {
    switch (searchCategory) {
      case "NEWS": {
        return titleString ? (
          <div className="item-body">
            <div style={{ display: "flex" }}>
              <div>
                <NewsItem
                  titleString={titleString}
                  title={title}
                  summary={summary}
                  listItemId={listItemId}
                />
              </div>
            </div>
          </div>
        ) : null;
      }
      case "RESOURCES": {
        return titleString ? (
          <div className="item-body">
            <div style={{ display: "flex" }}>
              <div>
                <ResourceItem
                  titleString={titleString}
                  listItemId={listItemId}
                  summary={summary}
                  webUrl={webUrl}
                />
              </div>
            </div>
          </div>
        ) : null;
      }
      case "VENDORS": {
        return titleString ? (
          <div className="item-body">
            <div style={{ display: "flex" }}>
              <div>
                <VendorsItem titleString={titleString} summary={summary} />
              </div>
            </div>
          </div>
        ) : null;
      }
      default: {
        return titleString ? (
          <div className="item-body">
            <div style={{ display: "flex" }}>
              <a className="title" href="#">
                <FontAwesomeIcon icon={faFileLines} color="#5fafd0" />
                {titleString}
              </a>
            </div>
          </div>
        ) : null;
      }
    }
  };

  return <Card className={`${helperContainer} h-100`}>{renderCard()}</Card>;
};
